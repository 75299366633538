<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Parts Enquiry</span>
      </div>
    </header>
    <section class="modal-card-body">
      <parts-enquiry :value="value"
        :selected-items.sync="selectedItems"
        :selected-vendor.sync="selectedVendor" />
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-primary tooltip"
        @click="showReport()"
        data-tooltip="Show report"
        :disabled="!isShowEnabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-file-document" />
        </span>
        <span>Print</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel"
        :disabled="cancelDisabled">
        <span>Cancel</span>
      </button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import PartsEnquiry from './PartsEnquiry'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { RemarkTypes, EmailAssetTypes } from '@/enums'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'PartsEnquiryModal',
  components: {
    BaseModalEx,
    PartsEnquiry
  },
  filters: {},
  mixins: [],
  props: {
    value: null,
    sendQuoteType: {
      type: Number,
      default: 0
    },
    quote: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-comment-question'
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      selectedItems: [],
      selectedVendor: null
    }
  },
  computed: {
    isShowEnabled() {
      return this.selectedItems.length > 0 && this.selectedVendor
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    showReport() {
      this.printReport()
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    async printReport() {
      const partsXml = this.generatePartsXml()
      const params = {
        CompanyID: this.quote.companyId,
        QuoteID: this.quote.quoteId,
        VendorID: this.selectedVendor.vendorId,
        reportDocId: this.$lzstring.compressToBase64(partsXml)
      }

      // Uses PrintPreviewEx component. Report parameters are set in either vuex or localStorage
      // Vuex when replacing existing route
      // localStorage when opening in new tab
      // let keyValuePairs = []
      // keyValuePairs.splice(keyValuePairs.length, 1, new KeyValuePairModel('CompanyID', this.quote.companyId ))
      // keyValuePairs.splice(keyValuePairs.length, 1, new KeyValuePairModel('QuoteID', this.quote.quoteId))
      // keyValuePairs.splice(keyValuePairs.length, 1, new KeyValuePairModel('VendorID', this.selectedVendor.vendorId))
      // keyValuePairs.splice(keyValuePairs.length, 1, new KeyValuePairModel('reportDocId', this.$lzstring.compressToBase64(partsXml)))
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      // Set jobcard report name
      const reportName = 'rptPartsEnquiry'

      const emailer = new Emailer()
      // console.log(this.selectedVendor)
      emailer.assetId = this.quote.quoteId
      emailer.assetType = EmailAssetTypes.Quote
      emailer.toEmails = this.selectedVendor.email ? [this.selectedVendor.email] : []
      emailer.remark = `Parts Enquiry for Quote ${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Parts Enquiry for Quote ${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      emailer.reportName = `PartsEnquiry_${this.quote.prefix}${this.quote.quoteNo}${this.quote.suffix}`
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    generatePartsXml() {
      let xmlDoc = document.implementation.createDocument('', '', null)
      let partEnquiryElement = xmlDoc.createElement('PARTSENQUIRY')
      const selected = _orderBy(this.selectedItems, 'sortNo')
      for (let i = 0; i < selected.length; i++) {
        let quoteItemElement = xmlDoc.createElement('QUOTEITEM')
        let itemDescElement = xmlDoc.createElement('ITEMDESC')
        let partNoElement = xmlDoc.createElement('PARTNO')
        let markElement = xmlDoc.createElement('MARK')
        // console.log(itemDescElement)
        itemDescElement.innerHTML = selected[i].itemDesc.escapeXml()
        partNoElement.innerHTML = selected[i].partNo.escapeXml()
        markElement.innerHTML = selected[i].mark.escapeXml()
        quoteItemElement.appendChild(itemDescElement)
        quoteItemElement.appendChild(partNoElement)
        quoteItemElement.appendChild(markElement)
        partEnquiryElement.appendChild(quoteItemElement)
      }
      xmlDoc.appendChild(partEnquiryElement)
      // console.log(new XMLSerializer().serializeToString(xmlDoc).toString())
      return new XMLSerializer().serializeToString(xmlDoc)
    },
    lzwEncode(s) {
      let dict = {}
      let data = (s + '').split('')
      let out = []
      let currChar
      let phrase = data[0]
      let code = 256
      for (let i = 1; i < data.length; i++) {
        currChar = data[i]
        if (dict[phrase + currChar] != null) {
          phrase += currChar
        } else {
          out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0))
          dict[phrase + currChar] = code
          code++
          phrase = currChar
        }
      }
      out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0))
      for (let i = 0; i < out.length; i++) {
        out[i] = String.fromCharCode(out[i])
      }
      return out.join('')
    },
    lzwDecode(s) {
      let dict = {}
      let data = (s + '').split('')
      let currChar = data[0]
      let oldPhrase = currChar
      let out = [currChar]
      let code = 256
      let phrase
      for (let i = 1; i < data.length; i++) {
        let currCode = data[i].charCodeAt(0)
        if (currCode < 256) {
          phrase = data[i]
        } else {
          phrase = dict[currCode] ? dict[currCode] : oldPhrase + currChar
        }
        out.push(phrase)
        currChar = phrase.charAt(0)
        dict[code] = oldPhrase + currChar
        code++
        oldPhrase = phrase
      }
      return out.join('')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
